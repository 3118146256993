<template>
  <div class="page">
    <el-container>
      <el-header>

        <Head :slide="slide" @logout="logout"></Head>
      </el-header>
      <el-main>
        <div class="">
          <ul class="bread-crumb">
            <li class="item item-1 fill" @click="$router.push('/post/upload?id=' + id)">
              {{ $t('report.post') + $t('report.upload') }}
            </li>
            <li class="item item-2 fill" @click="$router.push('/post/scale?id=' + id)">
              {{ $t('report.post') + $t('report.scale') }}
            </li>
            <li class="item item-3 fill" @click="$router.push('/post/level?id=' + id)">
              {{ $t('report.post') + $t('report.level') }}
            </li>
            <li class="item item-3 fill" @click="$router.push('/post/measure?id=' + id)">
              {{ $t('report.post') + $t('report.measure') }}
            </li>
            <li class="item item-3 fill" @click="$router.push('/post/installation?id=' + id)">
              {{ $t('report.post') + $t('report.install') }}
            </li>
            <li class="item item-4 fill">
              {{ $t('report.post') + $t('report.stand') }}
            </li>
            <li class="item item-5">
              {{ $t('report.post') + $t('report.create') }}
            </li>
          </ul>

          <div class="main">
            <div class="tab">
              <img src="@/assets/img/edit.png" alt="" />
              <span> {{ $t('report.stand') }}</span>
            </div>
            <div class="main_box">
              <div class="main_left">
                <div class="desc">
                  <span></span>
                  <span>{{ $t('report.t11') }}</span>
                </div>
                <div class="input_box">
                  <div>{{ $t('report.t10') }}:</div>
                  <el-select v-model="value" class="user_input" :placeholder="$t('report.placeholder')">
                    <el-option v-for="item in type" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                  </el-select>
                </div>
                <div class="input_box">
                  <div class="post1">{{ $t('report.s1') }}:</div>
                  <el-input class="user_input" @blur="getPost(0)" oninput="value=value.replace(/[^\d]/g,'')" clearable
                    v-model="user_post[0]" :placeholder="$t('report.placeholder') + '(mm)'"></el-input>
                </div>
                <div class="input_box">
                  <div class="post2">{{ $t('report.s2') }}:</div>
                  <el-input class="user_input" @blur="getPost(1)" oninput="value=value.replace(/[^\d]/g,'')" clearable
                    v-model="user_post[1]" :placeholder="$t('report.placeholder') + '(mm)'"></el-input>
                </div>
                <div class="input_box">
                  <div class="post3">{{ $t('report.s3') }}:</div>
                  <el-input class="user_input" @blur="getPost(2)" oninput="value=value.replace(/[^\d]/g,'')" clearable
                    v-model="user_post[2]" :placeholder="$t('report.placeholder') + '(mm)'"></el-input>
                </div>
                <div class="input_box">
                  <div class="post4">{{ $t('report.s4') }}:</div>
                  <el-input class="user_input" @blur="getPost(3)" oninput="value=value.replace(/[^\d]/g,'')" clearable
                    v-model="user_post[3]" :placeholder="$t('report.placeholder') + '(mm)'"></el-input>
                </div>
                <div class="input_box">
                  <div class="post5">{{ $t('report.s5') }}:</div>
                  <el-input class="user_input" @blur="getPost(4)" oninput="value=value.replace(/[^\d]/g,'')" clearable
                    v-model="user_post[4]" :placeholder="$t('report.placeholder') + '(mm)'"></el-input>
                </div>
                <div class="input_box">
                  <div class="post6">{{ $t('report.s6') }}:</div>
                  <el-input class="user_input" @blur="getPost(5)" oninput="value=value.replace(/[^\d]/g,'')" clearable
                    v-model="user_post[5]" :placeholder="$t('report.placeholder') + '(mm)'"></el-input>
                </div>
                <div class="desc" style="margin-bottom: 10px">
                  <span></span>
                  <span>{{ $t('report.remeasure') }}</span>
                </div>
                <ul class="step-list">
                  <li class="item" @click="$router.push('/post/level?id=' + id)">
                    1.{{ $t('report.level') }}
                  </li>
                  <li class="item" @click="$router.push('/post/measure?id=' + id)">
                    2.{{ $t('report.measure') }}
                  </li>
                  <li class="item" @click="$router.push('/post/installation?id=' + id)">
                    3.{{ $t('report.install') }}
                  </li>
                </ul>
              </div>
              <div class="main_right">
                <div class="desc">
                  <span></span>
                  <span>{{ $t('report.m7') }}</span>
                </div>
                <div class="sar_from">
                  <el-button class="make" @click="getFrom" type="primary">{{ $t('report.btnGetSAR') }}</el-button>
                </div>
                <!-- <iframe class="model-3d" id="beforeIframe" :src="$baseUrl + '/dabo/before3d?id=' + this.id + '&flag=0&before_after=1'" frameborder="0"></iframe> -->
                <div class="sar_input">
                  <div>
                    <div>
                      <span>{{ $t('report.t17') }}(mm):</span>
                      <el-input class="user_input" oninput="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"
                        clearable v-model="just_sar_dis" :placeholder="$t('report.placeholder') + '(mm)'"></el-input>
                    </div>
                    <div>
                      <el-radio v-model="just_in_out" label="0">{{ $t('report.param14') }}</el-radio>
                      <el-radio v-model="just_in_out" label="1">{{ $t('report.param15') }}</el-radio>
                    </div>
                  </div>
                  <div>
                    <div>
                      <span>{{ $t('report.t18') }}(mm):</span>
                      <el-input class="user_input" oninput="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"
                        clearable v-model="ce_sar_dis" :placeholder="$t('report.placeholder') + '(mm)'"></el-input>
                    </div>
                    <div>
                      <el-radio v-model="ce_in_out" label="0">{{ $t('report.param17') }}</el-radio>
                      <el-radio v-model="ce_in_out" label="1">{{ $t('report.param18') }}</el-radio>
                    </div>
                  </div>
                  <div>
                    <div>
                      <span>{{ $t('report.t19') }}(mm):</span>
                      <el-input class="user_input" oninput="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"
                        clearable v-model="zhou_sar_dis" :placeholder="$t('report.placeholder') + '(mm)'"></el-input>
                    </div>
                    <div>
                      <el-radio v-model="zhou_in_out" label="0">{{ $t('report.param27') }}</el-radio>
                      <el-radio v-model="zhou_in_out" label="1">{{ $t('report.param28') }}</el-radio>
                    </div>
                  </div>
                </div>

                <div class="other_box">
                  <div>{{ $t('report.t20') }}(mm/{{ $t('report.day') }}):</div>
                  <el-input class="other_input" oninput="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"
                    clearable v-model="max_save" :placeholder="$t('report.placeholder') + '(mm)'"></el-input>
                </div>

                <div class="other_box">
                  <div>{{ $t('report.t25') }}({{ $t('report.day') }}):</div>
                  <el-input class="other_input" oninput="value=value.replace(/[^\d]/g,'')" disabled clearable
                    v-model="min_time" :placeholder="$t('report.placeholder')"></el-input>
                  <!-- <span>计算</span> -->
                </div>
                <div class="other_box">
                  <div>{{ $t('report.t26') }}({{ $t('report.day') }}):</div>
                  <el-input class="other_input" oninput="value=value.replace(/[^\d]/g,'')" clearable v-model="change_time"
                    :placeholder="$t('report.placeholder')"></el-input>
                  <el-button class="make" @click="refresh" type="primary">{{ $t('report.refresh') }}</el-button>
                </div>

                <div class="other_box">
                  <div>{{ $t('report.t27') }}:</div>
                  <el-input class="other_input" :disabled="!this.is_open" oninput="value=value.replace(/[^\d]/g,'')"
                    clearable v-model="adjustment_time" :placeholder="$t('report.placeholder')"></el-input>
                  <el-switch class="switch" v-model="is_open" :active-text="$t('report.btn1')" inactive-text="">
                  </el-switch>
                </div>

                <div class="other_box">
                  <div>{{ $t('report.t28') }}:</div>
                  <el-input class="other_input" :disabled="!this.is_stretch"
                    oninput="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')" clearable v-model="stretch"
                    :placeholder="$t('report.placeholder') + '(mm)'"></el-input>
                  <el-switch class="switch" v-model="is_stretch" :active-text="$t('report.btn2')" inactive-text="">
                  </el-switch>
                </div>
                <div class="other_box">
                  <div>{{ $t('report.t29') }}:</div>
                  <el-input class="other_input" :disabled="!this.is_stretch" oninput="value=value.replace(/[^\d]/g,'')"
                    clearable v-model="stretch_day"
                    :placeholder="$t('report.placeholder') + '(' + $t('report.day') + ')'"></el-input>

                </div>
                <div class="other_box">
                  <div>{{ $t('report.t30') }}:</div>

                  <el-date-picker class="other_input" style="background: #ffffff" v-model="add_time" type="date"
                    format="yyyy-MM-dd" value-format="yyyy-MM-dd" :placeholder="$t('patient.bornPlaceholder')">
                  </el-date-picker>
                </div>

                <div class="btn_box">
                  <div>
                    <el-button @click="makeReport" class="make" type="primary">{{ $t('report.createpost') }}</el-button>
                  </div>
                  <div>
                    <el-button type="primary" @click="lastStep">{{ $t('report.lastStep') }}</el-button>
                  </div>
                </div>
              </div>
              <!-- <div class="right_side">
                  <ul class="step-list">
                      <li class="item">
                          1.水平点确定
                      </li>
                      <li class="item ">
                          2.畸形参数测量
                      </li>
                      <li class="item active">
                          3.安装参数测量
                      </li>
                  </ul>
              </div> -->
            </div>
          </div>
        </div>
      </el-main>
    </el-container>
  </div>
</template>
<script>
export default {
  data () {
    return {
      oldData: "",
      type: [
        { value: "0", label: this.$t('report.type2') },
        { value: "1", label: this.$t('report.type3') },
      ],
      value: "", //select的值
      user_post: ["", "", "", "", "", ""], //用户输入的立柱长度
      post_data: [], //接口放回的立柱数据
      just_sar_dis: "", //正位sar偏移
      just_in_out: "0", //正位 位于起点内侧/位于起点外侧:0=位于起点内侧,1=位于起点外侧
      ce_sar_dis: "", //侧位sar偏移
      ce_in_out: "0", //正位 位于起点内侧/位于起点外侧:0=位于起点内侧,1=位于起点外侧
      zhou_sar_dis: "", //轴位sar偏移
      zhou_in_out: "0", //轴位 位于起点内侧/位于起点外侧:0=位于起点内侧,1=位于起点外侧
      max_save: 1, //最大安全牵引速率（mm/天）
      // min_time: "", //最短矫正时间（天）
      change_time: "", //更改矫正时间（天）
      adjustment_time: "", //轴向调整时间（天）
      add_time: "", //处方开始时间（天）
      stretch: "", //拉伸距离（mm）
      is_open: false, //是否启用
      is_stretch: false, //是否先进行拉升
      slideText: "",
      slide: '',
      stretch_day: "", //拉伸天数
    };
  },
  watch:{
      $route(){
        this.$router.go()
      }
  },
  created () {
    if (!this.$route.query.id) {
      this.$message.error("病例不存在");
      return setTimeout((res) => {
        this.$router.go(-1);
      }, 500);
    }
    console.log('123131312')
    console.log(this.$route.query.p1)
    if (this.$route.query.p1 || this.$route.query.p2 || this.$route.query.p3 || this.$route.query.p4 || this.$route.query.p5 || this.$route.query.p6) {
      console.log('-----')
      this.copyAfterReport()
    } else {
      this.id = this.$route.query.id;
      this.getReportData();
    }
    
  },
  computed: {
    min_time () {
      return parseInt(Math.sqrt(Math.pow(this.just_sar_dis, 2) + Math.pow(this.ce_sar_dis, 2) + Math.pow(this.zhou_sar_dis, 2)) / this.max_save);
    },
  },
  methods: {
    refresh () {
      this.change_time = this.min_time;
    },
    getFrom () {
      if (this.oldData && this.oldData.base.length > 0) {
        var base = this.oldData.base;
        this.just_in_out = base.length > 0 ? base[0].slide : 0;
        this.just_sar_dis = base.length > 0 ? base[0].slide_dis : 0;
        this.ce_in_out = base.length > 1 ? base[1].slide : 0;
        this.ce_sar_dis = base.length > 1 ? base[1].slide_dis : 0;
        this.zhou_in_out = base.length > 2 ? base[2].slide : 0;
        this.zhou_sar_dis = base.length > 2 ? base[2].slide_dis : 0;
        this.change_time = this.min_time;
      } else {
        this.$message.error("未获取到参数");
      }
    },
    copyAfterReport () {

      var user_post = '';

      if (this.$route.query.p1) {
        user_post += this.$route.query.p1
      } else {
        user_post = '0'
      }
      if (this.$route.query.p2) {
        user_post += ','+this.$route.query.p2
      } else {
        user_post = ',0'
      }
      if (this.$route.query.p3) {
        user_post += ','+this.$route.query.p3
      } else {
        user_post = ',0'
      }
      if (this.$route.query.p4) {
        user_post += ','+this.$route.query.p4
      } else {
        user_post = ',0'
      }
      if (this.$route.query.p5) {
        user_post += ','+this.$route.query.p5
      } else {
        user_post = ',0'
      }
      if (this.$route.query.p6) {
        user_post += ','+this.$route.query.p6
      } else {
        user_post = ',0'
      }

      const params = {
        id: this.$route.query.id,
        user_input: user_post
      }


      this.$api
        .copyAfterReport(params)
        .then(res => {
          this.id = res.data.case_id;
          // this.getReportData();
          // this.$router.push('/post/stand?id=' + res.data.case_id);
          this.$router.push({path: '/post/stand', query: {id: res.data.case_id}, replace: true})
        })
      
    },
    getReportData () {
      this.$api
        .getData({ id: this.$route.query.id, before_after: 1 })
        .then((res) => {
          this.oldData = res.data;
          this.slideText = this.oldData.case.slide_text;
          this.slide = this.oldData.case.slide;
          var controlData = res.data.control;
          this.value = controlData ? controlData.post_type : "";
          this.post_data = controlData ? controlData.post_data : [];
          this.user_post = controlData ? controlData.user_input : [];
          this.just_in_out = controlData ? controlData.just_in_out : 0;
          this.just_sar_dis = controlData ? controlData.just_sar_dis : "";
          this.ce_in_out = controlData ? controlData.ce_in_out : 0;
          this.ce_sar_dis = controlData ? controlData.ce_sar_dis : "";
          this.zhou_in_out = controlData ? controlData.zhou_in_out : 0;
          this.zhou_sar_dis = controlData ? controlData.zhou_sar_dis : "";
          this.max_save = controlData ? controlData.max_save : 1;
          // this.min_time = controlData ? controlData.min_time : "";
          this.change_time = controlData ? controlData.change_time : this.min_time;
          this.adjustment_time = controlData ? controlData.adjustment_time : "";
          this.stretch = controlData ? controlData.stretch : "";
          this.stretch_day = controlData ? controlData.stretch_day : "";
          this.is_open = controlData && controlData.is_open ? true : false;
          this.is_stretch = controlData && controlData.is_stretch ? true : false;
          this.add_time = controlData && controlData.add_time_str ? controlData.add_time_str : this.getDateTime();
        });
    },
    getPost (index) {

      if (this.user_post[index] == undefined) return;
      if (this.value == "") {
        this.user_post.splice(index, 1);
        return this.$message.error("请选择支柱类型");
      }
      if (this.user_post[index] != "" && this.user_post[index] > 0) {
        this.$api
          .matchingPost({ type: this.value, long: this.user_post[index] })
          .then((res) => {
            if (res.code == 1) {
              this.post_data[index] = res.data;

            } else {
              this.$message.error(res.msg);
              this.post_data[index] = "";
              // this.user_post[index] = "";
            }
          })
          .catch((err) => {
            this.post_data[index] = "";
            // this.$message.error("系统繁忙");
          });
      }
    },
    makeReport () {
      var newIds = [];
      var newData = [];
      this.post_data.map((res) => {
        newIds.push(res.id);
        newData.push(res);
      });
      if (newData.length != 6) return this.$message.error("请选择支柱");
      var params = {
        id: this.$route.query.id,
        user_input: this.user_post.join(","),
        post_type: this.value,
        post_ids: newIds.join(","),
        post_data: JSON.stringify(newData),
        just_sar_dis: this.just_sar_dis, //正位sar偏移
        just_in_out: this.just_in_out, //正位 位于起点内侧/位于起点外侧:0=位于起点内侧,1=位于起点外侧
        ce_sar_dis: this.ce_sar_dis, //侧位sar偏移
        ce_in_out: this.ce_in_out, //正位 位于起点内侧/位于起点外侧:0=位于起点内侧,1=位于起点外侧
        zhou_sar_dis: this.zhou_sar_dis, //侧位sar偏移
        zhou_in_out: this.zhou_in_out, //正位 位于起点内侧/位于起点外侧:0=位于起点内侧,1=位于起点外侧
        max_save: this.max_save, //最大安全牵引速率（mm/天）
        min_time: this.min_time, //最短矫正时间（天）
        change_time: this.change_time, //更改矫正时间（天）
        adjustment_time: this.adjustment_time, //轴向调整时间（天）
        add_time: this.add_time, //处方开始时间（天）
        stretch: this.stretch, //拉伸距离（mm）
        stretch_day: this.stretch_day,
        is_open: this.is_open ? 1 : 0, //是否启用
        is_stretch: this.is_stretch ? 1 : 0, //是否先进行拉升
      };
      this.$api
        .saveControl(params)
        .then((res) => {
          if (res.code) {
            return this.$router.push("/post/report?id=" + this.id);
          }
          this.$message.error(res.msg);
        })
        .catch((err) => {
          // this.$message.error("系统繁忙");
        });
    },
    //获取当前日期
    getDateTime () {
      var nowDate = new Date();
      var year = nowDate.getFullYear();
      var month = nowDate.getMonth() + 1 < 10 ? "0" + (nowDate.getMonth() + 1)
        : nowDate.getMonth() + 1;
      var day = nowDate.getDate() < 10 ? "0" + nowDate.getDate() : nowDate
        .getDate();
      return year + "-" + month + "-" + day;

    },
    //退出登录
    logout () {
      this.$confirm("是否退出登录？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$api
            .logout()
            .then((res) => {
              if (res.code) {
                return this.$router.push("/login");
              }
              this.$message.error(res.msg);
            })
            .catch((err) => {
              // this.$message.error("系统繁忙");
            });
        })
    },
    //返回上一步
    lastStep () {
      this.$router.push("/post/installation?id=" + this.id);
    },
  },
};
</script>
<style scoped>
.page {
  background: #f1f5f7;
}

.warp {
  width: 1440px;
}

.el-main {
  padding: 0 20px;
}

.head {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.el-header {
  background: #ffffff;
  text-align: right;
}

.btn-logout {
  display: inline-block;
  padding: 8px 17px;
  padding-left: 37px;
  background: #f1f5f7;
  border: 1px solid #364284;
  border-radius: 14px;
  color: #364284;
  margin-right: 50px;
  position: relative;
}

.btn-logout::before {
  content: "";
  background: url(../../assets/img/off.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 50%;
  left: 18px;
  transform: translate(0%, -50%);
}

.btn-logout:hover {
  cursor: pointer;
}

.main {
  margin-top: 36px;
  background-color: #ffffff;
  padding: 23px 33px;
}

.main_box {
  display: flex;
}

.main_right {
  margin-left: 118px;
  position: relative;
}

.sar_from {
  position: absolute;
  right: 134px;
  top: 0px;
}

.tab img {
  height: 22px;
  width: 21px;
  vertical-align: middle;
  margin-right: 25px;
}

.tab span {
  vertical-align: middle;
  font-size: 20px;
  font-weight: 700;
  color: #000000;
}

.tab {
  border-bottom: 1px solid #f1f4f8;
  padding-bottom: 23px;
  margin-bottom: 36px;
}

.desc span:first-child {
  height: 17px;
  width: 6px;
  background-color: #364284;
  margin-right: 12px;
  display: inline-block;
  vertical-align: middle;
}

.desc span:last-child {
  font-size: 18px;
  color: #686868;
  vertical-align: middle;
}

.desc {
  margin-bottom: 39px;
}

.input_box {
  width: 320px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 26px;
}

.input_box>div:first-child {
  font-size: 18px;
  width: 80px;
  text-align: right;
}

.user_input {
  width: 216px;
}

.post1 {
  color: #ff0000;
}

.post2 {
  color: #ff7e00;
}

.post3 {
  color: #dcd404;
}

.post4 {
  color: #0dd608;
}

.post5 {
  color: #7ecdf6;
}

.post6 {
  color: #364284;
}

.sar_input {
  display: flex;
}

.sar_input>div {
  margin-right: 50px;
}

.sar_input>div>div:first-child {
  display: flex;
  align-items: center;
}

.sar_input>div>div:last-child {
  margin-top: 16px;
}

.sar_input .user_input {
  width: 130px;
}

.other_box {
  display: flex;
  margin-top: 27px;
  align-items: center;
}

.other_box>div {
  width: 180px;
  margin-right: 24px;
  text-align: right;
}

.other_box .other_input {
  width: 498px;
  background: #ffffff;
}

.other_box span {
  margin-left: 33px;
  background-color: #364284;
  border-radius: 15px;
  color: #fff;
  line-height: 31px;
  width: 90px;
  text-align: center;
}

.switch {
  margin-left: 26px;
}

.btn_box {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

.btn_box>div {
  text-align: center;
  line-height: 65px;
  margin-right: 33px;
}

.make {
  background-color: #364284;
  color: #ffffff;
}

.step-list {}

.step-list .item {
  background: #F1F5F7;
  border: 1px solid #364284;
  border-radius: 4px;
  color: #364284;
  margin-top: 14px;
  padding: 12px 22px;
  cursor: pointer;
}

.step-list .item:hover {
  background: #364284;
  color: #FFFFFF;
}

.step-list .active {
  background: #364284;
  color: #FFFFFF;
}

.model-3d {
  width: 100%;
  min-height: 300px;
}
</style>
